/** @jsx jsx */
import { gql } from '@ts-gql/tag';
import { useQuery } from '@ts-gql/apollo';

import { jsx } from '@reckon-web/core';
import { Text } from '@reckon-web/text';
import { TextLink } from '@reckon-web/text-link';
import { EntityAvatar } from '@reckon-web/avatar';
import { Flex } from '@reckon-web/flex';
import { Heading } from '@reckon-web/heading';
import { DataTable } from '@reckon-web/data-table';
import { Box } from '@reckon-web/box';
import { LoadingDots } from '@reckon-web/loading';
import { Stack } from '@reckon-web/stack';

import { useUser } from '../auth';
import { replaceWithHyphen } from '../../utilities/helpers/strings';
import { getClientName } from '../../utilities/dsUtils';
import { GenericError } from '../GenericError';
import { Wrapper } from '../Wrapper';

import { NoClients } from './NoClients';
import { ExportClients } from './ExportClients/ExportClientsDialog';

const getrecentClientsList = (tenant: string) => {
  const recents =
    JSON.parse(localStorage.getItem(`RecentClients_${tenant}`) as string) || [];
  return recents;
};

let ClientsPageQuery = gql`
  query RecentClientsList($ids: [ID!]) {
    clients(where: { ids: $ids }) {
      id
      sortName
      ... on Organisation {
        registeredName
      }
      ... on Person {
        firstName
        lastName
      }
      contactDetail {
        id
        primaryEmail
        primaryPhone
      }
    }
  }
` as import('../../../__generated__/ts-gql/RecentClientsList').type;

export function RecentClients() {
  const user = useUser();
  const recentClientsList = getrecentClientsList(user.profile.currentTenantId);

  let { data, error } = useQuery(ClientsPageQuery, {
    variables: { ids: recentClientsList },
  });

  if (error) {
    console.error(error);
    return <GenericError />;
  }
  if (!data) {
    return (
      <Flex
        width="100%"
        height="60vh"
        alignItems="center"
        justifyContent="center"
      >
        <LoadingDots label="loading..." size="large" />
      </Flex>
    );
  }

  const clients = data.clients || [];

  return (
    <Flex justifyContent="center" paddingX="medium">
      <Wrapper>
        <Stack marginY="xxlarge" gap="large">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            paddingRight="small"
          >
            <Heading level="1">Recent clients</Heading>
            <ExportClients />
          </Flex>
          {clients.length ? (
            <DataTable
              allowOverflow
              columns={{
                client: {
                  label: 'Client',
                  render: (index) => {
                    return (
                      <Flex alignItems="center">
                        <EntityAvatar
                          name={getClientName(clients[index]) || ''}
                          type={clients[index].__typename}
                          size="small"
                        />
                        <Box paddingX="large">
                          <TextLink
                            id={`Clients-Button-${replaceWithHyphen(
                              clients[index].sortName
                            )}-open`}
                            href={`/client?client=${clients[index].id}`}
                          >
                            {clients[index].sortName || ''}
                          </TextLink>
                        </Box>
                      </Flex>
                    );
                  },
                },
                email: {
                  label: 'Email',
                  render: (index) => (
                    <Text size="small" color="muted">
                      {clients[index].contactDetail?.primaryEmail}
                    </Text>
                  ),
                },
                phone: {
                  label: 'Phone',
                  render: (index) => (
                    <Text size="small" color="muted">
                      {clients[index].contactDetail?.primaryPhone}
                    </Text>
                  ),
                },
              }}
              rowCount={data.clients.length}
            />
          ) : (
            <NoClients />
          )}
        </Stack>
      </Wrapper>
    </Flex>
  );
}
