/** @jsx jsx */
import { Fragment, useState } from 'react';
import { gql } from '@ts-gql/tag';
import { useApolloClient } from '@ts-gql/apollo';

import { jsx } from '@reckon-web/core';
import { Text } from '@reckon-web/text';
import { Flex } from '@reckon-web/flex';
import { Heading } from '@reckon-web/heading';
import { Box } from '@reckon-web/box';
import { LoadingDots } from '@reckon-web/loading';
import { Button, IconButton } from '@reckon-web/button';
import { DownloadIcon } from '@reckon-web/icon';
import { ContentDialog } from '@reckon-web/modal-dialog';
import { Stack } from '@reckon-web/stack';
import { Tooltip } from '@reckon-web/tooltip';
import { Notice } from '@reckon-web/notice';
import { useToasts } from '@reckon-web/toast';

import { useError } from '../../../utilities/hooks/useError';
import { downloadURI } from '../../../utilities/helpers/functions';
import { ADMIN, useAccessLevel } from '../../access';

let ExportAllClients = gql`
  query ExportAllClients {
    exportClient {
      url
    }
  }
` as import('../../../../__generated__/ts-gql/ExportAllClients').type;

export const ExportClients = () => {
  const apolloClient = useApolloClient();
  const { addToast } = useToasts();
  const handleError = useError();
  const accessLevel = useAccessLevel();

  const [isOpen, setOpen] = useState(false);
  const [beginExport, setBeginExport] = useState(false);
  const [loadingClients, setLoadingClients] = useState(false);

  const handleExport = async () => {
    setBeginExport(true);
    setLoadingClients(true);
    try {
      const { data } = await apolloClient.query({
        query: ExportAllClients,
        fetchPolicy: 'network-only',
      });

      data?.exportClient?.url &&
        downloadURI(data.exportClient.url, 'Contacts-plus-clients.csv');

      addToast({ title: 'Export client list successful', tone: 'positive' });
      setLoadingClients(false);
      setBeginExport(false);
      setOpen(false);
    } catch (error) {
      handleError({ title: 'Error fetching clients', error });
      setLoadingClients(false);
      setBeginExport(false);
    }
  };

  return (
    <Fragment>
      {(accessLevel === ADMIN && (
        <Tooltip content="Export clients as CSV" placement="bottom">
          <IconButton
            icon={DownloadIcon}
            onClick={() => setOpen(true)}
            label="Download"
            weight="subtle"
          />
        </Tooltip>
      )) ||
        null}
      <ContentDialog
        size="medium"
        isOpen={isOpen}
        onClose={() => ''}
        header={
          <Box padding="xlarge">
            <Text weight="semibold" size="xsmall" color="dim">
              EXPORT CLIENTS
            </Text>
            <Heading level="2">Export CSV file</Heading>
          </Box>
        }
        footer={
          <Flex justifyContent="flex-end" gap="small" padding="large">
            <Button
              label="Close"
              onClick={() => setOpen(false)}
              weight="none"
              tone="passive"
            />
            <Button
              label="Export"
              onClick={handleExport}
              loading={loadingClients}
            />
          </Flex>
        }
      >
        <Stack padding="xlarge" gap="xlarge">
          {beginExport ? (
            <Flex gap="medium" alignItems="center">
              <LoadingDots label="loading..." />
              {loadingClients && (
                <Text size="small" color="dim">
                  Fetching clients, please wait
                </Text>
              )}
            </Flex>
          ) : (
            <Fragment>
              <Notice>
                Please note you will only be able to export clients that your
                user has access to.
              </Notice>
              <Text color="muted">
                Exporting allows you to view all your APS clients in a
                downloaded csv file
              </Text>
            </Fragment>
          )}
        </Stack>
      </ContentDialog>
    </Fragment>
  );
};
