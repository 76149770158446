/** @jsx jsx */
import { jsx } from '@reckon-web/core';
import { Text } from '@reckon-web/text';
import { Heading } from '@reckon-web/heading';
import { Stack } from '@reckon-web/stack';

export function NoClients() {
  return (
    <Stack
      gap="xlarge"
      padding="xxlarge"
      marginTop="xxlarge"
      marginBottom="xxlarge"
      position="relative"
      top={78}
      width="80%"
      css={{ margin: '0 auto' }}
    >
      <Heading level="3" align="center">
        No recent clients
      </Heading>
      <Text color="muted" weight="medium" align="center">
        Please search for a client or create a new one.
      </Text>
    </Stack>
  );
}
