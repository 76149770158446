/** @jsx jsx */
import { ReactElement } from 'react';

import { jsx } from '@reckon-web/core';
import { Flex } from '@reckon-web/flex';
import { LoadingDots } from '@reckon-web/loading';
import { Stack } from '@reckon-web/stack';
import { Text } from '@reckon-web/text';

type PageLoadProps = {
  loadingText?: string | ReactElement;
  loadSize?: 'small' | 'medium' | 'large';
  height?: string;
};

export const PageLoad = ({
  loadingText,
  loadSize = 'medium',
  height = '50vh',
}: PageLoadProps) => (
  <Flex
    width="100%"
    height={height}
    alignItems="center"
    justifyContent="center"
  >
    <Stack gap="medium" align="center">
      <LoadingDots label="loading..." size={loadSize} />
      {loadingText && (
        <Text size="small" color="dim">
          {loadingText}
        </Text>
      )}
    </Stack>
  </Flex>
);
