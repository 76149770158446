export function downloadURI(uri: string, name: string) {
  let link = document.createElement('a');
  // Name of the downloaded file, only works from resources from the same origin
  link.download = name;
  link.href = uri;
  // Append to html link element page
  document.body.appendChild(link);
  // Start download
  link.click();
  // Clean up and remove the link
  document.body.removeChild(link);
}
