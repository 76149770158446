/** @jsx jsx */
import { useRouter } from 'next/router';

import { jsx } from '@reckon-web/core';

import { LOCAL_STORAGE_REDIRECT_URL_KEY } from './auth';
import { PageLoad } from './common/PageLoad';
import { RecentClients } from './clients';

const DEFAULT_REDIRECT_URI = process.env.REACT_APP_COGNITO_REDIRECT_URI || '/';

export function LandingRoute() {
  const router = useRouter();

  const localStorageRedirectUrl = localStorage.getItem(
    LOCAL_STORAGE_REDIRECT_URL_KEY
  );

  if (localStorageRedirectUrl) {
    const redirectUrl = localStorageRedirectUrl || DEFAULT_REDIRECT_URI;

    router.push(redirectUrl);

    localStorage.removeItem(LOCAL_STORAGE_REDIRECT_URL_KEY);

    return <PageLoad />;
  }

  return <RecentClients />;
}
